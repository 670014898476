import { Box, Button, Tab, Tabs } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import Pagination from '@material-ui/lab/Pagination';
import { useCreateIRA, useIRASessions } from 'apiHooks/wmsHooks';
import Link from 'components/Link';
import React, { useMemo, useState } from 'react';
import Select from 'react-select';
import { Modal } from 'rsuite';
import { useBranchId } from 'utils/customHooks';
import { formatDateTime, getDateRange } from 'utils/utils';
import WmsFilters from 'wms/WmsFilters';

const initialDates = getDateRange(new Date(), 7);

export default function Ira() {
  const branchId = useBranchId();
  const [dateRange, setDateRange] = useState([]);
  const [createIraDialogVisibility, setCreateIraDialogVisibility] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const offset = useMemo(() => (activePage - 1) * 12, [activePage]);

  const { data: sessionList, isFetching: isFetchingIra } = useIRASessions({
    branchId,
    startDate: dateRange[0],
    endDate: dateRange[1],
  });
  const [createIraSession, { status }] = useCreateIRA({ branchId });

  const handlePageChange = (e, value) => {
    setActivePage(value);
  };

  const onDateFilterChanged = (dates) => {
    setDateRange(dates.length ? dates : initialDates);
  };

  return (
    <div>
      {isFetchingIra || status === 'loading' ? (
        <LinearProgress
          classes={{
            colorPrimary: 'bg-green-300',
          }}
        />
      ) : null}

      <div className="flex flex-col w-full h-full">
        {/* Header Section containing Filters */}
        <div className="flex flex-row m-5 justify-center items-center">
          <div className="flex-1">
            <p className="text-base md:text-2xl antialiased sm:subpixel-antialiased md:antialiased font-semibold md:font-semibold mx-5">
              Inventory Count Session List
            </p>
          </div>
          <WmsFilters
            onFiltersChange={onDateFilterChanged}
            onFilterCancelled={() => onDateFilterChanged([])}
          />
        </div>
        {/* Session List Section */}
        <div className="grid grid-cols-1 md:grid-cols-4">
          {sessionList?.slice(offset, offset + 12).map((item) => {
            return <IraSessionItem key={item?.id} session={item} />;
          })}
        </div>
        {/* Pagination section */}
        <div className="mt-5 m-auto">
          {sessionList?.length / 12 > 1 && (
            <Pagination onChange={handlePageChange} count={Math.ceil(sessionList?.length / 12)} />
          )}
        </div>
      </div>

      {/* Create ira session fab button */}
      <div className="fixed right-5 md:right-5 bottom-5 md:bottom-5">
        <Fab
          color="primary"
          aria-label="add"
          onClick={() => {
            setCreateIraDialogVisibility(true);
          }}
        >
          <AddIcon />
        </Fab>
      </div>

      {/* create irs session dialog */}
      <Modal
        size="sm"
        // overflow={true}
        show={createIraDialogVisibility}
        onHide={() => setCreateIraDialogVisibility(false)}
      >
        <Modal.Body>
          <CreateIraDialog
            onCreateClicked={() => {
              createIraSession();
              setCreateIraDialogVisibility(false);
            }}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

function CreateIraDialog({ onCreateClicked }) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Typography className="mb-3 ml-4" variant="h5">
        Create Inventory Count
      </Typography>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Location Based" />
          <Tab label="Product Based" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <LocationBasedIRACreate />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ProductBasedIRACreate />
      </TabPanel>
      <Button
        className="ml-4 mt-4"
        variant="contained"
        color="primary"
        onClick={() => onCreateClicked()}
      >
        Create
      </Button>
    </Box>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const data = {
  area: ['A1', 'A2', 'A3'],
  zone: ['Z1', 'Z2', 'Z3'],
  bin: ['B1', 'B2', 'B3'],
  skuCategory: ['Baby Care', 'Grooming', 'Home Care', 'Laundry'],
  sku: [
    'PAM DPR LG(60X2)X1 D8 PH SUMMERPANTS BOX',
    'PAM DPR XL(52X2)X1 D8 PH SUMMERPANTS BOX',
    '556 GIL SKINGUARD RAZOR 1UP 1X6X6 STK',
    'BII FLEX ELHDLDIS 8 2 10X12X6',
    'BII FLEXIBLE STREAMER 1 1X12X48',
    'AMBI GELFR DOWNY (180GX2)X4 AS BP',
    'AMBI GELFR R.LEMN (180GX2)X4 BP LEGO AS',
    'Ariel Bar Cut-Up Bar 125gx96',
    'TIDE LAU PWD 84G(6+1)X30 PLAT C&B BP',
    'TIDE LAU PWD 865GX24 BRILLIANT WHITE ME2',
  ],
};

function LocationBasedIRACreate() {
  return (
    <div className="mb-8 mt-4">
      <Dropdown label="Area" options={data.area} />
      <Dropdown label="Zone" options={data.zone} placeholder="All" />
      <Dropdown label="Bin" options={data.bin} placeholder="All" />
    </div>
  );
}

function ProductBasedIRACreate() {
  return (
    <div className="mb-8 mt-4">
      <Dropdown label="SKU Category" options={data.skuCategory} />
      <Dropdown label="SKU" options={data.sku} placeholder="All" />
    </div>
  );
}

function Dropdown({ label, options, ...rest }) {
  return (
    <div className="flex w-full items-center my-2">
      <Typography className="mr-3 w-28">{label} </Typography>
      <div className="w-80">
        <Select
          options={options.map((val) => ({ value: val, label: val }))}
          isMulti
          // menuPortalTarget={document.getElementById('portal-target')}
          {...rest}
        />
      </div>
    </div>
  );
}

// function CreateIraDialogContent({ onCreateClicked }) {
//   return (
//     <div>
//       <p className="m-5">This will create IRA for all items</p>
//       {sessionTypeList.map((item) => {
//         return (
//           <div
//             className="rounded md:rounded-lg shadow md:shadow-lg bg-white m-5 p-3"
//             onClick={() => onCreateClicked()}
//           >
//             <p>{item?.name}</p>
//           </div>
//         );
//       })}
//     </div>
//   );
// }

function IraSessionItem(props) {
  const { session } = props;

  return (
    <div className="rounded md:rounded-lg shadow md:shadow-lg bg-white m-5 p-3">
      <div className="flex flex-row">
        <Link to={`/wms/iraDetails/${session?.id}/${session?.code}/${session?.state}`}>
          <div className="flex-2 flex-col flex-grow">
            <p className="text-base md:text-base font-bold md:font-bold m-1">{session?.code}</p>
            <div className="flex flex-col">
              <p className="text-xs md:text-xs font-medium md:font-medium m-1">
                {formatDateTime(new Date(session?.createdAt))}
              </p>
              <p className="text-xs md:text-xs font-medium md:font-medium ml-1">{session?.state}</p>
            </div>
          </div>
        </Link>
      </div>
      {/* <div className="flex flex-row py-2">
        <div className="flex flex-col flex-grow w-20 h-12 rounded md:rounded-lg shadow md:shadow-lg bg-white justify-center items-center mx-1">
          <p>{`${session?.progress?.done}/${session?.progress?.target}`}</p>
          <p>Batches Completed</p>
        </div>
      </div> */}
    </div>
  );
}

// const sessionTypeList = [
//   {
//     id: 'all',
//     name: 'Create IRA for all',
//   },
// ];
