import React from 'react';
import Layout from 'components/Layout';
import { ReactQueryConfigProvider } from 'react-query';
import Ira from 'wms/ira/Ira';

export default function IraContainer(props) {
  return (
    <ReactQueryConfigProvider
      config={{ suspense: false, throwOnError: false, useErrorBoundary: false }}
    >
      <Layout {...props}>
        <div className="bg-background h-full">
          <Ira />
        </div>
      </Layout>
    </ReactQueryConfigProvider>
  );
}
