import IconButton from '@material-ui/core/IconButton';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import TuneRoundedIcon from '@material-ui/icons/TuneRounded';
import DateTimePicker from 'components/inputs/DateTimePicker';
import React, { useState } from 'react';

export default function WmsFilters(props) {
  const { onFiltersChange, onFilterCancelled, show = false } = props;
  const [showFilters, setShowFilters] = useState(show);
  return (
    <div className="flex flex-row items-center space-x-2">
      {showFilters ? (
        <DateTimePicker
          twclass="sm:w-5/12 md:w-4/12 lg:w-4/12 xl:w-4/12"
          onChange={onFiltersChange}
          maxDays={90}
        />
      ) : null}

      <div className="flex flex-row items-center space-x-2">
        {showFilters ? (
          <IconButton
            onClick={() => {
              setShowFilters(false);
              onFilterCancelled();
            }}
          >
            <CancelRoundedIcon />
          </IconButton>
        ) : (
          <IconButton
            onClick={() => {
              setShowFilters(true);
            }}
          >
            <TuneRoundedIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
}
