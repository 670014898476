import { saveAs } from 'file-saver';
import { queryCache, useMutation, useQuery } from 'react-query';
import fetch from 'utils/fetch';
import { encodeQueryParams } from 'utils/utils';

const QueriesKeys = {
  workerList: 'workerList',
  trips: 'trips',
  returns: 'returns',
  ira: 'ira',
  iraSession: 'iraSession',
  returnsTaskDetails: 'returnsTaskDetails',
  inboundSession: 'inboundSession',
  putawaySession: 'putawaySession',
  pickingSessionList: 'pickingSessionList',
  pickingSessionDetails: 'pickingSessionDetails',
  loadingTasks: 'loadingTasks',
  ewaybill: 'ewaybill',
  infraStatus: 'infraStatus',
  outboundSession: 'outboundSession',
  inboundTrips: 'inboundTrips',
  returnsTrips: 'returnsTrips',
  outboundTrips: 'outboundTrips',
  ptlDashboard: 'ptlDashboard',
  overallSorting: 'overallSorting',
  liSort: 'liSort',
  ptlSort: 'ptlSort',
};

const inboundQueryKey = ({ queryType }) => {
  return queryType ? QueriesKeys.putawaySession : QueriesKeys.inboundSession;
};

// ******* Queries ********

export const useWMSWorkers = ({ branchId, onSuccess }) => {
  return useQuery(
    branchId && [QueriesKeys.workerList, branchId],
    () =>
      fetch({
        url: 'wms/workforce/worker',
        method: 'GET',
        headers: { 'x-wh-id': branchId },
      }),
    { onSuccess }
  );
};

export const usePickingStats = ({ nodeId, tripIds, onSuccess }) => {
  const query = encodeQueryParams({ nodeId });

  return useQuery(
    nodeId && tripIds && [QueriesKeys.picking_stats, nodeId, tripIds],
    () =>
      fetch({
        url: `wms-picking/session/statistics?${query}`,
        method: 'POST',
        headers: { 'x-wh-id': nodeId },
        data: { tripIds },
      }),
    { onSuccess }
  );
};

export const usePickingTrips = ({ nodeId, startDate, endDate, onSuccess }) => {
  const query = encodeQueryParams({ nodeId, startDate, endDate });
  return useQuery(
    nodeId && [QueriesKeys.trips, nodeId, startDate, endDate],
    () =>
      fetch({
        url: `wms-picking/trip?${query}`,
        method: 'GET',
        headers: { 'x-wh-id': nodeId },
      }),
    { onSuccess }
  );
};

export const useIRASessions = ({ branchId, startDate, endDate, onSuccess }) => {
  return useQuery(
    branchId && [QueriesKeys.ira, branchId, endDate, startDate],
    () =>
      fetch({
        url: 'wms/ira/session',
        method: 'GET',
        headers: { 'x-wh-id': branchId },
        params: {
          createdAtWindowStart: startDate,
          createdAtWindowEnd: endDate,
        },
      }),
    { onSuccess }
  );
};

export const useIRASessionDetails = ({ branchId, sessionId, onSuccess }) => {
  return useQuery(
    branchId && [QueriesKeys.iraSession, branchId, sessionId],
    () =>
      fetch({
        url: `wms/ira/session/${sessionId}/details`,
        method: 'GET',
        headers: { 'x-wh-id': branchId },
      }),
    { onSuccess }
  );
};

export const useWMSReturnsTasks = ({ branchId, startDate, endDate, onSuccess }) => {
  return useQuery(
    branchId && [QueriesKeys.returns, branchId, endDate, startDate],
    () =>
      fetch({
        url: 'wms/returns/task',
        method: 'GET',
        headers: {
          'x-wh-id': branchId,
        },
        params: {
          createdAtWindowStart: startDate,
          createdAtWindowEnd: endDate,
        },
      }),
    { onSuccess }
  );
};

export const useReturnsTasksDetails = ({ sessionId, branchId, onSuccess }) => {
  return useQuery(
    branchId && [QueriesKeys.returnsTaskDetails, branchId],
    () =>
      fetch({
        url: `wms/returns/task/${sessionId}/transfer`,
        method: 'GET',
        headers: { 'x-wh-id': branchId },
      }),
    {
      onSuccess,
    }
  );
};

export const useSortingSessionData = ({ branchId, sessionCode, onSuccess, query = {} }) => {
  return useQuery(
    branchId && ['sortingTasks', { branchId, query }],
    () =>
      fetch({
        url: `wms/sortation/session`,
        method: 'GET',
        headers: { 'x-wh-id': branchId },
        params: { includeTasks: true, sessionCode, ...query },
      }),
    {
      onSuccess: (data) => {
        onSuccess && onSuccess(data);
      },
    }
  );
};

export const useInboundSession = ({
  queryType,
  sessionId,
  branchId,
  startDate,
  endDate,
  onSuccess,
}) => {
  // query params
  const query = encodeQueryParams({
    sessionId,
    createdAtWindowStart: startDate,
    createdAtWindowEnd: endDate,
    includeTasks: true,
  });

  const qKey = inboundQueryKey({ queryType });

  return useQuery(
    branchId && [qKey, sessionId, branchId, startDate, endDate],
    () =>
      fetch({
        url: `wms/cincout/${queryType || 'inbound'}/session?${query}`,
        method: 'GET',
        headers: { 'x-wh-id': branchId },
      }),
    {
      onSuccess,
    }
  );
};

export const usePickingSession = ({ branchId, startDate, endDate, onSuccess }) => {
  // query params
  const query = encodeQueryParams({
    createdAtWindowStart: startDate,
    createdAtWindowEnd: endDate,
    includeTasks: true,
  });

  return useQuery(
    branchId && [QueriesKeys.pickingSessionList, branchId, startDate, endDate],
    () =>
      fetch({
        url: `wms/cincout/picking/session?${query}`,
        method: 'GET',
        headers: { 'x-wh-id': branchId },
      }),
    {
      onSuccess,
    }
  );
};

export const usePickingSessionDetails = ({ branchId, sessionId, onSuccess }) => {
  return useQuery(
    branchId && [QueriesKeys.pickingSessionDetails, branchId],
    () =>
      fetch({
        url: `wms/cincout/picking/session/${sessionId}/details`,
        method: 'GET',
        headers: { 'x-wh-id': branchId },
      }),
    {
      onSuccess,
    }
  );
};

export const useLoadingTasks = ({
  branchId,
  planDateWindowStart,
  planDateWindowEnd,
  createdAtWindowStart,
  createdAtWindowEnd,
  onSuccess,
}) => {
  const props = {
    planDateWindowStart,
    planDateWindowEnd,
    createdAtWindowEnd,
    createdAtWindowStart,
  };
  const query = encodeQueryParams(props);

  return useQuery(
    branchId && props && [QueriesKeys.loadingTasks, branchId, props],
    () =>
      fetch({
        url: `wms/mmloading/task?${query}`,
        method: 'GET',
        headers: { 'x-wh-id': branchId },
      }),
    {
      onSuccess,
    }
  );
};

export const useLoadingTaskDetails = ({ branchId, taskId, onSuccess }) => {
  return useQuery(
    branchId && [QueriesKeys.loadingTasks, branchId],
    () =>
      fetch({
        url: `wms/mmloading/task/${taskId}/details`,
        method: 'GET',
        headers: { 'x-wh-id': branchId },
      }),
    {
      onSuccess,
    }
  );
};

export const useInfraStatus = ({ branchId }) => {
  return useQuery(branchId && [QueriesKeys.infraStatus, branchId], () =>
    fetch({
      url: `wms/infra/lights/status`,
      method: 'GET',
      headers: { 'x-wh-id': branchId },
    })
  );
};

export const useSessionParams = ({
  planDateWindowStart,
  planDateWindowEnd,
  createdAtWindowStart,
  createdAtWindowEnd,
}) => {
  //
  const props = {
    planDateWindowStart,
    planDateWindowEnd,
    createdAtWindowEnd,
    createdAtWindowStart,
  };
  return encodeQueryParams(props);
};
export const useOutboundSession = ({ branchId, onSuccess, query }) => {
  return useQuery(
    branchId && query && [QueriesKeys.outboundSession, branchId, query],
    () =>
      fetch({
        url: `wms/outbound/session?${query}`,
        method: 'GET',
        headers: { 'x-wh-id': branchId },
      }),
    {
      onSuccess,
    }
  );
};

export const useInboundCockpitTrips = ({ branchId, onSuccess, query }) => {
  return useQuery(
    branchId && query && [QueriesKeys.inboundTrips, query, branchId],
    () =>
      fetch({
        url: `wms/inbound/cockpit?${query}`,
        method: 'POST',
        headers: { 'x-wh-id': branchId },
      }),
    {
      onSuccess,
      // refetchInterval: 2000,
    }
  );
};

export const useReturnsCockpitTrips = ({ branchId, onSuccess, query }) => {
  return useQuery(
    branchId && query && [QueriesKeys.returnsTrips, query, branchId],
    () =>
      fetch({
        url: `wms/cl_returns/cockpit?${query}`,
        method: 'POST',
        headers: { 'x-wh-id': branchId },
      }),
    {
      onSuccess,
      // refetchInterval: 2000,
    }
  );
};
export const useOutboundCockpitTrips = ({ branchId, onSuccess, query }) => {
  return useQuery(
    branchId && query && [QueriesKeys.outboundTrips, query, branchId],
    () =>
      fetch({
        url: `wms/outbound/trips?${query}`,
        method: 'POST',
        headers: { 'x-wh-id': branchId },
      }),
    {
      onSuccess,
      // refetchInterval: 2000,
    }
  );
};

export const useOutboundTasks = ({ branchId, onSuccess, kind }) => {
  return useQuery(
    branchId && [QueriesKeys.outboundSession, branchId],
    () =>
      fetch({
        url: `wms/outbound/${kind}`,
        method: 'GET',
        headers: { 'x-wh-id': branchId },
      }),
    {
      onSuccess,
    }
  );
};

export const usePtlSortingDashboard = ({
  branchId,
  sessionId,
  onError = () => 0,
  onSuccess = () => 0,
  autoRefresh = false,
}) => {
  return useQuery(
    branchId && sessionId && [QueriesKeys.ptlDashboard, branchId, sessionId],
    () =>
      fetch({
        url: `wms/sortation/session/${sessionId}/sbl/dashboard`,
        method: 'POST',
        headers: { 'x-wh-id': branchId },
      }),
    {
      onError,
      onSuccess,
      refetchInterval: autoRefresh ? 30 * 1000 : false,
    }
  );
};

export const useOverallSortingSessionData = ({
  branchId,
  sessionId,
  onError = () => 0,
  autoRefresh = false,
}) => {
  return useQuery(
    branchId && sessionId && [QueriesKeys.overallSorting, branchId, sessionId],
    () =>
      fetch({
        url: `wms/sortation/session/${sessionId}/dashboard`,
        method: 'GET',
        headers: { 'x-wh-id': branchId },
      }),
    {
      onError,
      refetchInterval: autoRefresh ? 30 * 1000 : false,
    }
  );
};

export const useLiSortSessionData = ({
  branchId,
  sessionId,
  onError = () => 0,
  autoRefresh = false,
  onSuccess = () => 0,
}) => {
  return useQuery(
    branchId && sessionId && [QueriesKeys.liSort, branchId, sessionId],
    () =>
      fetch({
        url: `wms/sortation/session/${sessionId}/lisort/dashboard`,
        method: 'GET',
        headers: { 'x-wh-id': branchId },
      }),
    {
      onError,
      onSuccess,
      refetchInterval: autoRefresh ? 30 * 1000 : false,
    }
  );
};

export const usePTLSessionData = ({
  branchId,
  sessionId,
  onError = () => 0,
  onSuccess = () => 0,
  autoRefresh = false,
}) => {
  return useQuery(
    branchId && sessionId && [QueriesKeys.ptlSort, branchId, sessionId],
    () =>
      fetch({
        url: `wms/sortation/session/${sessionId}/ptl/dashboard`,
        method: 'POST',
        headers: { 'x-wh-id': branchId },
        data: { sessionId },
      }),
    {
      onError,
      onSuccess,
      refetchInterval: autoRefresh ? 30 * 1000 : false,
    }
  );
};

// ******* Mutations ********

export const useReturnsAssignWorker = ({ sessionId, branchId, onSuccess }) => {
  return useMutation(
    ({ workerIds }) =>
      fetch({
        url: `wms/returns/task/assign_worker`,
        method: 'POST',
        headers: { 'x-wh-id': branchId },
        data: { taskId: sessionId, workerIds },
      }),
    {
      onSuccess: (data) => {
        queryCache.refetchQueries([QueriesKeys.returnsTaskDetails]);
        onSuccess && onSuccess(data);
      },
    }
  );
};

export const useIraAssignWorker = ({ sessionId, branchId, onSuccess }) => {
  return useMutation(
    ({ workerIds }) =>
      fetch({
        url: `wms/ira/session/${sessionId}/assign_worker`,
        method: 'POST',
        headers: { 'x-wh-id': branchId },
        data: { workerIds },
      }),
    {
      onSuccess: (data) => {
        queryCache.refetchQueries([QueriesKeys.ira, QueriesKeys.iraSession]);
        onSuccess && onSuccess(data);
      },
    }
  );
};

export const usePickingAssignWorker = ({ sessionId, branchId, onSuccess }) => {
  return useMutation(
    ({ taskId, workerId }) =>
      fetch({
        url: `wms/cincout/picking/session/${sessionId}/task/${taskId}/assign_worker`,
        method: 'POST',
        headers: { 'x-wh-id': branchId },
        data: { workerId },
      }),
    {
      onSuccess: (data) => {
        queryCache.refetchQueries([QueriesKeys.pickingSessionDetails]);
        onSuccess && onSuccess(data);
      },
    }
  );
};

export const useCreateIRA = ({ branchId, onSuccess }) => {
  return useMutation(
    () =>
      fetch({
        url: 'wms/ira/session',
        method: 'POST',
        headers: { 'x-wh-id': branchId },
      }),
    {
      onSuccess: (data) => {
        queryCache.refetchQueries([QueriesKeys.ira]);
        onSuccess && onSuccess(data);
      },
    }
  );
};

export const useUpdateIRA = ({ branchId, onSuccess }) => {
  return useMutation(
    ({ updateId, damagedQty, quantity }) =>
      fetch({
        url: `wms/ira/update/${updateId}/approve`,
        method: 'POST',
        headers: { 'x-wh-id': branchId },
        data: {
          damagedQtyL0: damagedQty,
          qtyL0: quantity,
        },
      }),
    {
      onSuccess: (data) => {
        queryCache.refetchQueries([QueriesKeys.ira, QueriesKeys.iraSession]);
        onSuccess && onSuccess(data);
      },
    }
  );
};

export const useCompleteIRA = ({ sessionId, branchId, onSuccess }) => {
  return useMutation(
    () =>
      fetch({
        url: `wms/ira/session/${sessionId}/complete`,
        method: 'POST',
        headers: { 'x-wh-id': branchId },
      }),
    {
      onSuccess: (data) => {
        queryCache.refetchQueries([QueriesKeys.ira, QueriesKeys.iraSession]);
        onSuccess && onSuccess(data);
      },
    }
  );
};

export const useMMLoadingAssignWorker = ({ branchId, onSuccess }) => {
  return useMutation(
    ({ taskId, workerIds, apiType }) =>
      fetch({
        url: `wms/mmloading/task/${taskId}/${apiType}`,
        method: 'POST',
        headers: { 'x-wh-id': branchId },
        data: { workerIds },
      }),
    {
      onSuccess: (data) => {
        queryCache.refetchQueries([QueriesKeys.loadingTasks]);
        onSuccess && onSuccess(data);
      },
    }
  );
};

export const useUpdateReturnTask = ({ branchId, onSuccess }) => {
  return useMutation(
    ({ updateId, quantity }) =>
      fetch({
        url: `wms/returns/task/transfer`,
        method: 'POST',
        headers: { 'x-wh-id': branchId },
        data: { id: updateId, qtyL0: quantity },
      }),
    {
      onSuccess: (data) => {
        queryCache.refetchQueries(QueriesKeys.returnsTaskDetails);
        onSuccess && onSuccess(data);
      },
    }
  );
};

export const useCompleteReturnTask = ({ branchId, sessionId, onSuccess }) => {
  return useMutation(
    () =>
      fetch({
        url: `wms/returns/task/${sessionId}/complete`,
        method: 'POST',
        headers: { 'x-wh-id': branchId },
      }),
    {
      onSuccess: (data) => {
        queryCache.refetchQueries(QueriesKeys.returnsTaskDetails);
        onSuccess && onSuccess(data);
      },
    }
  );
};

export const useCreateInboundSession = ({ queryType, branchId, onSuccess }) => {
  const qKey = inboundQueryKey({ queryType });
  return useMutation(
    () =>
      fetch({
        url: `wms/cincout/${queryType || 'inbound'}/session`,
        method: 'POST',
        headers: { 'x-wh-id': branchId },
      }),
    {
      onSuccess: (data) => {
        queryCache.refetchQueries(qKey);
        onSuccess && onSuccess(data);
      },
    }
  );
};

export const useCompleteInbound = ({ queryType, branchId, sessionId, onSuccess }) => {
  const qKey = inboundQueryKey({ queryType });
  return useMutation(
    () =>
      fetch({
        url: `wms/cincout/${queryType || 'inbound'}/session/${sessionId}/force_compelete`,
        method: 'POST',
        headers: { 'x-wh-id': branchId },
      }),
    {
      onSuccess: (data) => {
        queryCache.refetchQueries(qKey);
        onSuccess && onSuccess(data);
      },
    }
  );
};
//

export const useCreatePickingSession = ({ nodeId, onSuccess }) => {
  const query = encodeQueryParams({ nodeId });

  return useMutation(
    ({ tripIds }) =>
      fetch({
        url: `/wms-picking/session?${query}`,
        method: 'POST',
        headers: { 'x-wh-id': nodeId },
        data: { tripIds },
      }),
    {
      onSuccess: (data) => {
        queryCache.refetchQueries([QueriesKeys.pickingSessionList, QueriesKeys.outboundTrips]);
        onSuccess && onSuccess(data);
      },
    }
  );
};

export const useEWayBillGenerate = ({ branchId }) => {
  return useMutation(
    ({ type, data }) =>
      fetch({
        url: `wms/mmloading/eway_bill/${type || 'primary_doc'}/generate`,
        method: 'POST',
        responseType: 'blob',
        wholeResponse: true,
        headers: {
          'Content-Type': 'application/json',
          'x-wh-id': branchId,
        },
        data,
      }),
    {
      onSuccess: (data) => {
        saveAs(data.data, data.headers.filename || `Eway-Bill`);
      },
    }
  );
};

export const useForceComplete = ({ branchId }) => {
  return useMutation(
    ({ code }) =>
      fetch({
        url: `/wms/task/force_complete`,
        method: 'POST',
        headers: { 'x-wh-id': branchId },
        params: { code },
      }),
    {
      onSuccess: () => queryCache.refetchQueries('sortingTasks'),
    }
  );
};

export const useSessionComplete = ({ branchId }) => {
  return useMutation(
    ({ type = 'inbound', sessionId }) =>
      fetch({
        url: `/wms/${type}/session/${sessionId}/complete`,
        method: 'POST',
        headers: { 'x-wh-id': branchId },
      }),
    {
      onSuccess: () =>
        queryCache.refetchQueries([QueriesKeys.outboundTrips, QueriesKeys.inboundTrips]),
    }
  );
};

export const useOutboundTaskComplete = ({ branchId }) => {
  return useMutation(
    ({ taskId, kind }) =>
      fetch({
        url: `/wms/outbound/${kind}/${taskId}/complete`,
        method: 'POST',
        headers: { 'x-wh-id': branchId },
      }),
    {
      onSuccess: () => queryCache.refetchQueries('sortingTasks'),
    }
  );
};
export const useMMLoadingForceComplete = ({ branchId }) => {
  return useMutation(
    ({ taskId }) =>
      fetch({
        url: `/wms/mmloading/task/${taskId}/force_complete`,
        method: 'POST',
        headers: { 'x-wh-id': branchId },
      }),
    {
      onSuccess: () => queryCache.refetchQueries(QueriesKeys.loadingTasks),
    }
  );
};

export const useMMLoadingLoadAll = ({ branchId }) => {
  return useMutation(
    ({ taskId }) =>
      fetch({
        url: `wms/mmloading/task/${taskId}/load_all`,
        method: 'POST',
        headers: { 'x-wh-id': branchId },
      }),
    {
      onSuccess: () => queryCache.refetchQueries(QueriesKeys.loadingTasks),
    }
  );
};

export const useInfraTest = ({ branchId }) => {
  return useMutation(
    ({ stationCode, test }) =>
      fetch({
        url: `wms/infra/lights/test?${encodeQueryParams({ stationCode, test })}`,
        method: 'POST',
        headers: { 'x-wh-id': branchId },
      }),
    {
      onSuccess: () => queryCache.refetchQueries(QueriesKeys.infraStatus),
    }
  );
};

//
export const useWMSBinsUpload = ({ branchId }) => {
  return useMutation(({ url, data }) =>
    fetch({
      url: `wms/${url}`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json, blob', 'x-wh-id': branchId },
      data,
    })
  );
};

export const useWMSBinsDownload = ({ branchId }) => {
  return useMutation(
    ({ url }) =>
      fetch({
        url: url.toString().includes('ob_priority')
          ? `bwms/${url}`
          : url.toString().includes('parking')
          ? `wms/yms/${url}`
          : `${url}`,
        method: 'GET',
        responseType: 'blob',
        wholeResponse: true,
        headers: { 'x-wh-id': branchId },
        params: { nodeId: branchId },
      }),
    {
      onSuccess: (data) => {
        saveAs(data.data, data.headers.filename);
      },
    }
  );
};

export const useOutboundCreateSession = ({ branchId, onSuccess }) => {
  return useMutation(
    ({ tripIds }) =>
      fetch({
        url: `wms/outbound/session`,
        method: 'POST',
        headers: { 'x-wh-id': branchId },
        data: { tripIds },
      }),
    {
      onSuccess: (data) => {
        queryCache.refetchQueries(QueriesKeys.outboundTrips);
        onSuccess && onSuccess(data);
      },
    }
  );
};

export const useWMSHUsDownload = ({ branchId }) => {
  return useMutation(
    () =>
      fetch({
        url: `wms/handling_unit/kind/xlsx`,
        method: 'GET',
        responseType: 'blob',
        wholeResponse: true,
        headers: { 'x-wh-id': branchId },
      }),
    {
      onSuccess: (data) => {
        saveAs(data.data, data.headers.filename);
      },
    }
  );
};
