import moment from 'moment';
import React, { useState } from 'react';
import { DateRangePicker } from 'rsuite';

const Ranges = [
  {
    label: 'Today',
    value: [moment().toDate(), moment().toDate()],
  },
  {
    label: 'Yesterday',
    value: [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
  },
  {
    label: 'Last 7 Days',
    value: [moment().subtract(7, 'days').toDate(), moment().toDate()],
  },
  {
    label: 'This Month',
    value: [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
  },
  {
    label: 'Last Month',
    value: [
      moment().subtract(1, 'months').startOf('month').toDate(),
      moment().subtract(1, 'months').endOf('month').toDate(),
    ],
  },
];

export default function DateTimePicker(props) {
  const { onChange, maxDays = undefined, placeholder = 'Select Date Range', size } = props;

  const extraProps = {
    disabledDate: maxDays ? DateRangePicker.allowedMaxDays(maxDays) : undefined,
    oneTap: maxDays ? maxDays == 1 : undefined,
  };
  const [state, setState] = useState([]);
  return (
    <DateRangePicker
      value={state}
      ranges={Ranges}
      size={size ? size : 'lg'}
      className="text-black"
      format="YYYY-MM-DD"
      placement="auto"
      {...extraProps}
      placeholder={placeholder}
      onChange={(value) => {
        setState(value);
        value.length > 0
          ? onChange([moment(value[0]).format('YYYY-MM-DD'), moment(value[1]).format('YYYY-MM-DD')])
          : onChange([]);
      }}
    />
  );
}
